import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import "pure-react-carousel/dist/react-carousel.es.css";



export function FullRow(){

    const data = useStaticQuery(graphql`
    query {
        datalogsJson {
          cfc {
            body {
              title
              content
              images {
                caption
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    `)

    const cfcData = data.datalogsJson.cfc.body;

    return (
        <>
        <div className="flex flex-col text-gray-800 mx-8 mb-14 dark:text-white">
            <div role="header" className="text-2xl text-sgreen">{cfcData[0].title}</div>
            <div className="border-b-4 border-sgreen w-1/12 my-2" />
            <div className="my-4 text-justify">
            {cfcData[0].content.map((body, index) => {
                return (<>
                <p>{body}</p>
                </>);
            })}
            </div>
            <div className="flex flex-wrap mx-auto">
            {cfcData[0].images.map((content, index) => {
                return (<>
                <div className="mb-8">
                <GatsbyImage 
                image={content.image.childImageSharp.gatsbyImageData}
                />
                <span className="italic font-bold block">{content.caption}</span>
                </div>
                </>)
            })}
            </div>
        </div>
        <div className="flex flex-col text-gray-800 mx-8 mb-14 dark:text-white">
            <div role="header" className="text-2xl text-sgreen">{cfcData[1].title}</div>
            <div className="border-b-4 border-sgreen w-1/12 my-2" />
            <div className="my-4 text-justify">
            {cfcData[1].content.map((body, index) => {
                return (<>
                <p>{body}</p>
                </>);
            })}
            </div>
            <div className="flex flex-wrap mx-auto">
            {cfcData[1].images.map((content, index) => {
                return (<>
                <div className="mb-8">
                <GatsbyImage 
                image={content.image.childImageSharp.gatsbyImageData}
                />
                <span className="italic font-bold block">{content.caption}</span>
                </div>
                </>)
            })}
            </div>
        </div>
        <div className="flex flex-col text-gray-800 mx-8 mb-14 dark:text-white">
            <div role="header" className="text-2xl text-sgreen">{cfcData[2].title}</div>
            {/* <div className="border-b-4 border-sgreen w-1/12 my-2" /> */}
            <div className="my-4 text-justify">
            {cfcData[2].content.map((body, index) => {
                return (<>
                <p>{body}</p>
                </>);
            })}
            </div>
            <div className="flex flex-wrap">
            {cfcData[2].images.map((content, index) => {
                return (<>
                <div className="w-full md:w-1/2 flex flex-col">
                <GatsbyImage 
                image={content.image.childImageSharp.gatsbyImageData}
                className="mx-auto"
                />
                <span className="italic font-bold mx-auto">{content.caption}</span>
                </div>
                </>)
            })}
            </div>
        </div>
        <div className="flex flex-col text-gray-800 mx-8 mb-14 dark:text-white">
            <div role="header" className="text-2xl text-sgreen">{cfcData[3].title}</div>
            <div className="border-b-4 border-sgreen w-1/12 my-2" />
            <div className="my-4 text-justify">
            {cfcData[3].content.map((body, index) => {
                return (<>
                <p>{body}</p>
                </>);
            })}
            </div>
            <div className="flex flex-wrap mx-auto">
            {cfcData[3].images.map((content, index) => {
                return (<>
                <div className="mb-8">
                <GatsbyImage 
                image={content.image.childImageSharp.gatsbyImageData}
                />
                <span className="italic font-bold">{content.caption}</span>
                </div>
                </>)
            })}
            </div>
        </div>
        {/* <div className="flex flex-col text-gray-800 mt-8 mx-8">
            <div role="header" className="text-2xl">{csrData[1].title}</div>
            <div className="border-b-4 border-sgreen w-1/12 my-2" />
            <div className="my-2 text-justify">
            {csrData[1].content}
            </div>
            <div className="flex flex-wrap mx-auto">
            {csrData[1].images.map((image, index) => {
                return (<>
                <GatsbyImage 
                image={image.childImageSharp.gatsbyImageData}
                />
                </>)
            })}
            </div>
        </div>
        <div className="flex flex-col text-gray-800 mt-8 mx-8">
            <div role="header" className="text-2xl">{csrData[2].title}</div>
            <div className="border-b-4 border-sgreen w-1/12 my-2" />
            <div className="my-2 text-justify">
            {csrData[2].content}
            </div>
            <div className="flex flex-wrap mx-auto">
            {csrData[2].images.map((image, index) => {
                return (<>
                <GatsbyImage 
                image={image.childImageSharp.gatsbyImageData}
                />
                </>)
            })}
            </div>
        </div>
        <div className="flex flex-col text-gray-800 mt-8 mx-8">
            <div role="header" className="text-2xl">{csrData[3].title}</div>
            <div className="border-b-4 border-sgreen w-1/12 my-2" />
            <div className="my-2 text-justify">
            {csrData[3].content}
            </div>
            <div className="flex flex-wrap mx-auto">
            {csrData[3].images.map((image, index) => {
                return (<>
                <GatsbyImage 
                image={image.childImageSharp.gatsbyImageData}
                />
                </>)
            })}
            </div>
        </div>
        <div className="flex flex-col text-gray-800 mt-8 mx-8">
            <div role="header" className="text-2xl">{csrData[4].title}</div>
            <div className="border-b-4 border-sgreen w-1/12 my-2" />
            <div className="my-2 text-justify">
            {csrData[4].content}
            </div>
            <div className="flex flex-wrap mx-auto">
            {csrData[4].images.map((image, index) => {
                return (<>
                <GatsbyImage 
                image={image.childImageSharp.gatsbyImageData}
                />
                </>)
            })}
            </div>
            </div> */}
        </>
    )
}