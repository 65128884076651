import React from "react";
import { SiteMeta } from "../general/seo";
import { TopBar, BottomBar } from "../general/extremeBars";
import NavBar from '../general/navbar'
import { FullRow } from "./cfc";

export default function FoodChain(){


    return (<>
    <SiteMeta title="Cassava Food Chain - Psaltry International Company Limited" />
    <div className="dark:bg-gray-800">
    <TopBar/>
    <NavBar />

    <div className="2xl:mx-auto 2xl:container flex justify-center bg-white dark:bg-gray-800">
            <div className="2xl:px-20 px-2 md:px-6 py-12 w-full lg:w-4/5">
            <div className="flex flex-col text-3xl p-6 text-sgreen">
                <span class="font-bold">Cassava Food Chain</span>
            </div>
                <FullRow />
        </div>
        </div>
        </div>
    
    
    <BottomBar />
    </>);
}